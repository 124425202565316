import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { addTeacher } from "../redux/apiCalls/TeachersApiCalls";
import { toast } from "react-toastify";
import Select from "react-select";

export default function CreateTeacher({ open, setOpen }) {
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("teacher");
  const [password, setPassword] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [locationsSelect, setLocatoinsSelect] = useState([]);
  const [acadimicLevel, setAcadimicLevel] = useState([]);
  const [price, setPrice] = useState([]);
  const [gender, setGender] = useState("");
  const [educationalInstitutions, setEducationalInstitutions] = useState("");

  const dispatch = useDispatch();

  const subjects = useSelector((state) => state.subject.subjects);
  const locations = useSelector((state) => state.location.locations);
  const acadimics = useSelector((state) => state.acadimic.acadimics);

  const { loading } = useSelector((state) => state.teacher);

  const handlerAddTeacher = (e) => {
    e.preventDefault();

    if (!userName || !phoneNumber || !password) {
      return toast.error("complete your data");
    }

    const infoTeacher = {
      userName,
      phoneNumber,
      password,
      role,
      subjects: selectedSubjects?.map((subject) => subject?.value),
      locations: locationsSelect?.map((location) => location?.value),
      academicLevel: acadimicLevel?.map((acadimic) => acadimic?.value),
      price,
      gender,
      educationalInstitutions,
    };

    dispatch(addTeacher(infoTeacher, setOpen));
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const optionsSubjects = subjects?.map((subject) => ({
    value: subject?.id,
    label: subject?.name,
  }));

  const optionsLessons = locations?.map((location) => ({
    value: location?.id,
    label: location?.name,
  }));

  const optionsAcadimics = acadimics?.map((academic) => ({
    value: academic?.id,
    label: academic?.name,
  }));

  const genders = [
    { _id: 1, name: "male" },
    { _id: 2, name: "female" },
  ];

  return (
    <div>
      <div
        className={`transition-all  ease duration-[0.4s] ${
          open ? "right-0" : "right-[-720px]"
        } absolute w-[720px] bg-[#f6f6f6] top-0 h-[100%] z-20`}
      >
        <div className="flex items-center w-[100%] justify-between pr-[12px] pl-[12px] pt-[6px] pb-[10px] border-b-[1px] border-[#ebe9f1]">
          <p>إضافة استاذ</p>
          <div className="union">
            <IoMdClose
              className="cursor-pointer"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
        <div className="w-[100%] mx-auto   h-[94%] rounded-md shadow-md">
          <form
            onSubmit={handlerAddTeacher}
            className="flex h-[100%]  flex-col justify-between items-strat mx-4"
          >
            <div>
              <div className="mb-4">
                <label
                  htmlFor="userName"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  الإسم :
                </label>
                <input
                  type="text"
                  id="userName"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  name="userName"
                  placeholder="Enter username"
                  className="appearance-none border rounded-md w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phoneNumber"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  رقم الهاتف :
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  className="appearance-none border rounded-md w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  كلمة السر :
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  className="appearance-none border rounded-md w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  السعر :
                </label>
                <input
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  className="appearance-none border rounded-md w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  المؤسسات التعليمية :
                </label>
                <input
                  type="text"
                  value={educationalInstitutions}
                  onChange={(e) => setEducationalInstitutions(e.target.value)}
                  id="academic"
                  name="academicIns"
                  placeholder="Enter academic"
                  className="appearance-none border rounded-md w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="subjects"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  اختر المواد :
                </label>
                <Select
                  className="text-sm"
                  isMulti
                  onChange={(selectedOptions) =>
                    setSelectedSubjects(selectedOptions)
                  }
                  value={selectedSubjects}
                  options={optionsSubjects}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="locations"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  اختر الموقع :
                </label>
                <Select
                  className="text-sm"
                  isMulti
                  onChange={(selectedOptions) =>
                    setLocatoinsSelect(selectedOptions)
                  }
                  value={locationsSelect}
                  options={optionsLessons}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="acadimics"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  المستوى التعليمي :
                </label>
                <Select
                  className="text-sm"
                  isMulti
                  onChange={(selectedOption) =>
                    setAcadimicLevel(selectedOption)
                  }
                  value={acadimicLevel}
                  options={optionsAcadimics}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="gender"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  الحنس :
                </label>
                <select
                  id="location"
                  value={gender}
                  onChange={handleGenderChange}
                  className="appearance-none border text-gray-700 rounded-md w-full py-2 text-sm px-3  leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Gender</option>
                  {genders?.map((gender) => (
                    <option key={gender?._id} value={gender?.name}>
                      {gender?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-center justify-around w-[100%] pl-[12px] pr-[12px] pb-[12px]">
              <button
                className="pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#82868b] font-[400] flex items-center justify-center transition-[0.3s] hover:bg-[#82868b06]"
                onClick={() => {
                  setOpen(false);
                }}
              >
                إلغاء
              </button>
              <button
                onClick={handlerAddTeacher}
                className="send-btn pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#fff] font-[400] flex items-center justify-center bg-[#7367f0] transition-[0.3s]"
              >
                إضافة
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
